// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   name:'testing in local',
//   envName:'local',
//   apiUrl:'https://fu458fka5j.execute-api.ca-central-1.amazonaws.com/UAT/application',
//   qrCodeUrl:'https://verification.staging.rogersbank.com',
//   xapiKey:'Bqp1vURDDxyrAxmsYQhn7b9JWz6EB6L3Fpn73eHb',
//   adobeScript:'https://assets.adobedtm.com/8cf1b6f99d16/6668466f6850/launch-01c434742411-development.min.js'
// };

export const environment = {
    production: false,
    envName:'staging',
    apiUrl: 'https://dxpkniho1k.execute-api.ca-central-1.amazonaws.com/v1/application/',
    qrCodeUrl:'https://verification.staging.rogersbank.com',
    xapiKey:'mureOMHlNf7XsQ7tx4GB1rvNypIG8fA44oBxDz65',
    adobeScript:'https://assets.adobedtm.com/8cf1b6f99d16/6668466f6850/launch-01c434742411-development.min.js',
    //adobeScript:'https://assets.adobedtm.com/8cf1b6f99d16/6668466f6850/launch-EN7b9c4a0353944c2e8fa7c97e9a08224b-staging.min.js'
    API_Key :'AIzaSyCEI54v71Oc5bumVgcftDdhWGxs3Z9wHvE',
    siteKey:{
      v3:'6LdL80okAAAAAJni22M5bPgGgQoI-qlsl9fOi--3',
      v2:'6LfEBCYqAAAAAAEdhgKP6RbpK7gVp-BhOpiylU_g'
    }
  };



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
