import { enableProdMode, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { loadAdobeScript } from './app/app.component';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { UserIdleModule } from 'angular-user-idle';
import { provideAnimations } from '@angular/platform-browser/animations';
// import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { ErrorHandlerInterceptor } from './app/services/error-handler.interceptor';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module,RecaptchaModule ,RecaptchaFormsModule } from 'ng-recaptcha';
import { environment as environment_1 } from 'src/environments/environment';
import { LoaderInterceptorService } from './app/services/loader-interceptor.service';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { BackendApiService } from './app/services/backend-api.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { ContentfulService } from './app/services/contentful.service';
import { routes } from './app/app.routes';
import { provideRouter } from '@angular/router';
const script = document.createElement('script');


if (environment.production) {
  window.console.log = function() {}
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, UserIdleModule.forRoot({ idle: 1800, timeout: 2, ping: 120 }), RecaptchaV3Module,RecaptchaModule,RecaptchaFormsModule ),
        ContentfulService, BnNgIdleService, BackendApiService,
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
        { provide: APP_INITIALIZER, useFactory: loadAdobeScript, multi: true },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.siteKey.v3 },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
        },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(routes)
    ]
})
  .catch(err => console.error(err));
