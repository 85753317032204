import { Component, OnInit } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api.service';
import { ContentfulService } from 'src/app/services/contentful.service';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
    standalone: true,
    imports: [RouterOutlet],
})
export class NavbarComponent implements OnInit {
  language: any;
  logo_image: any;
  footerlinks:Array<any>=[];
  copyRight: any;
  helpCTA: any;
  headerData: any;

  constructor(
    private contentfulService:ContentfulService,
    public apiservice:BackendApiService
    ) {}

  ngOnInit(): void {
    let lang = sessionStorage.getItem('lang');
    if (lang == 'en') {
      this.language = 'FRANÇAIS';
    } else if (lang == 'fr') {
      this.language = 'ENGLISH';
    } else {
      this.language = 'ENGLISH';
    }
   this.getContentfulData(lang)

  }

  changeLanguage() {
    if (this.language == 'FRANÇAIS') {
       this.contentfulService.language.next("fr")
       this.getContentfulData('fr')
       sessionStorage.setItem('lang','fr');
       sessionStorage.setItem('lang','fr');

      this.language = 'ENGLISH';
    } else {
      this.contentfulService.language.next("en")
      this.getContentfulData('en')
      this.language = 'FRANÇAIS';
      sessionStorage.setItem('lang','en');
    }
  }



  getContentfulData(currentLang:any) {
    this.contentfulService
      .getEntry(currentLang ,'header', {  include: 10 })
      .then((header) => {
        this.logo_image = header[0].fields.logo.fields.image.fields.file;
        this.headerData=header[0].fields
      });
      this.contentfulService.getEntry(currentLang ,'footer', {  include: 10 })
      .then((footer) => {
        this.footerlinks = footer[0].fields.links.fields.links;
        let year=footer[0].fields.copyrightText
        this.copyRight = year.replace('##YEAR##', new Date().getFullYear()); 
        this.helpCTA=footer[0].fields.helpCta.fields.text
      });
  }



}



