import { Component } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BackendApiService } from './services/backend-api.service';
import { LoaderInterceptorService } from './services/loader-interceptor.service';
import { ContentfulService } from './services/contentful.service';
declare let AcuantJavascriptWebSdk: any;
import { set } from 'lodash';
import * as interactions from './shared/interactions.json'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent {
  title = 'eKYC-App';
  sessionID: any;
  cookieValue: any;
  loading: boolean = false;
  allowRoute: boolean = false;
  interaction_types: any = (interactions as any).default
  appPendingAdobe: any;
  eventPageName: any;

  constructor(
    private router: Router,
    private bnIdle: BnNgIdleService,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private apiService: BackendApiService,
    private loader: LoaderInterceptorService,
    private contentService: ContentfulService,
    
  ) {
    this.apiService.isLoading.subscribe((v) => {
      this.loading = v;
    });
   
  }


  
  ngOnInit() {
    this.getAcuantCred();
    this.getQueryParams();
    //Session out for ideal screen login
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const rt = this.getChild(this.activatedRoute);
        rt.data.subscribe((data: { title: string }) => {
          this.titleService.setTitle(data.title);
        });
      });

    this.bnIdle.startWatching(1800).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        this.router.navigate(['/ekyc/sessionout']);
        setTimeout(() => {
          sessionStorage.clear();
        }, 1000);
      }
    });
    this.routeChangeEvent();
    this.getEventsData();
  }

  getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  //get params from the url
  getQueryParams() {
    this.route.queryParams.subscribe((params) => {
      if (params.sId) {
        sessionStorage.setItem('sessionId', params.sId);
        if (params.pac) {
          sessionStorage.setItem('pac', params.pac);
        }
        this.checkstatus(params);
        this.storeparams(params);
        this.languageStore(params);
      }
      else if (params.sessionId) {
        sessionStorage.setItem('sessionId', params.sessionId);
        this.storeparams(params);
        this.languageStore(params);
      } else if (params.lang) {
        this.languageStore(params);
      }
    });
  }


  languageStore(params: any) {
    console.log(params);
    if (
      params.lang == undefined ||
      params.lang == null ||
      params.lang == ''
    ) {
      sessionStorage.setItem('lang', 'en');
    } else if (params.lang.match(/ENGLISH|english|EN|en/)) {
      sessionStorage.setItem('lang', 'en');
    } else if (params.lang.match(/FRENCH|FRANÇAIS|FR|fr/)) {
      sessionStorage.setItem('lang', 'fr');
    }
  }

  //calling initiate api for status
  checkstatus(params: any) {
    if (params.sId) {
      let data = {
        sessionId: params.sId,
        stage: 'STATUS',
      };
      this.apiService.initiate(data).subscribe(
        (response) => {
          if (response?.ekycStatus == 'COMPLETED') {
            this.router.navigate(['/ekyc/ekyc_completed']);
            sessionStorage.removeItem('sessionId');
          } else if (response?.ekycStatus == 'EXPIRED') {
            this.router.navigate(['/ekyc/application_expired']);
            sessionStorage.removeItem('sessionId');
          } else if (response?.ekycStatus == 'PENDING') {
            this.storeparams(params);
          } else if (response?.errorMessage) {
            this.router.navigate(['/ekyc/technical_error']);
            sessionStorage.removeItem('sessionId');
          } else {
            this.router.navigate(['/ekyc/technical_error']);
          }
        },
        (error) => {
          this.router.navigate(['/ekyc/technical_error']);
          sessionStorage.removeItem('sessionId');
        }
      );
    }
  }

  //store params
  storeparams(params: any) {
    sessionStorage.setItem('mobile', params.mob);
    sessionStorage.setItem('province', params.prov);
    sessionStorage.setItem('cpc', params.cpc);
    sessionStorage.setItem('channel', params.chan);
    if (params.device) {
      sessionStorage.setItem('device_count', params.device);
    }

    //redirecting to pages based on province and channel
    setTimeout(() => {
      if (params.prov == 'QC' && params.chan != 'RET' && params.chan != 'SSP' && params.chan != 'OVR') {
        console.log("***",params.prov)
        this.router.navigate(['/ekyc/application_pending']);
        sessionStorage.removeItem('sessionId');
      } else if (params.chan == 'CNX') {
        this.router.navigate(['/ekyc/application_pending']);
        sessionStorage.removeItem('sessionId');
      }
      else if (params.chan == 'RET' || params.chan == 'SSP' || params.chan == 'OVR') {
        this.router.navigate(['/ekyc/retail']);
        sessionStorage.removeItem('sessionId');
      }
      else {
        this.deleteQueryParameterFromCurrentRoute();
      }
    }, 500);
  }

  //removing the params from the url after stroing
  deleteQueryParameterFromCurrentRoute() {
    const params = { ...this.route.snapshot.queryParams };
    delete params.sId;
    delete params.mob;
    delete params.prov;
    delete params.lang;
    delete params.pac;
    delete params.sessionId;
    delete params.cpc;
    delete params.device;
    delete params.chan;
    this.router.navigate([], { queryParams: params });
  }

  // Acuant sdk initial start 
  onAcuantSdkLoaded(response: any) {
    AcuantJavascriptWebSdk.setUnexpectedErrorCallback(this.unexpectedError);
    this.init(response);
  }

  getAcuantCred() {
    this.apiService.getcred().subscribe((response) => {
      this.onAcuantSdkLoaded(response);
      this.contentService.contentCred(response);
      this.allowRoute = true;
    })
  }

  init(keys: any) {
    let acuant_cred = keys.accuantSecrets
    let Credential = {
      id_username: acuant_cred.idUsername,
      id_password: acuant_cred.idPassword,
      acas_endpoint: acuant_cred.accuantEndpoint,
    };
    let base64Token = btoa(
      Credential.id_username + ':' + Credential.id_password
    );
    AcuantJavascriptWebSdk.initialize(base64Token, Credential.acas_endpoint, {
      onSuccess: function () {
        AcuantJavascriptWebSdk.start(() => {console.log("AcuantJavascriptWebSdk") });
      },
      onFail: function (code: any, description: any) {
        console.log('initialize failed ' + code + ': ' + description);
      },
    });
  }

  //-------------------------------------
  //  if sdk fails to load
  //--------------------------------------

  unexpectedError(errorMsg: any) {
    console.error('Got an unexpected error callback:', errorMsg);
  }

  //-------------------------------------
  //route events capture |  page view 
  //--------------------------------------
  routeChangeEvent() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.contentService.applicationPendingAdobe$.subscribe((appPending) => {
          this.appPendingAdobe = appPending
        });
        if(!event.url.includes('?')){
          this.eventPageName = event.url;
          // console.log("&&&&&&",event.url)
          let payload = {};
          const pageName = event.url.split('/').slice(-1)[0];
          //selfServeStart global
          if (pageName === 'front_document_capture') {
            set(payload, 'events.selfServeStart', true);
            set(payload, 'selfServe.type', 'transaction');
            set(payload, 'selfServe.name', 'eKYC - photo');
          }
          //selfServeStart global

          //selfServeComplete photo
          if (pageName === 'selfie_time') {
            set(payload, 'events.selfServeComplete', true);
            set(payload, 'selfServe.type', 'transaction');
            set(payload, 'selfServe.name', 'eKYC - photo');
          }
          //selfServeComplete photo

          //miscellaneous
          if (pageName === 'selfie_time') {
            set(payload, 'miscellaneous.type', 'self serve');
            set(payload, 'miscellaneous.name', 'selfie');
            set(payload, 'ecommerce.paymentMethod', 'rogers mastercard');
          }
          //miscellaneous

          //selfServeComplete global
          if (pageName === 'application_pending' && this.appPendingAdobe) {
            set(payload, 'events.selfServeComplete', true);
            set(payload, 'selfServe.type', 'transaction');
            set(payload, 'selfServe.name', 'eKYC - global');
            set(payload, 'miscellaneous.type', 'self serve');
            set(payload, 'miscellaneous.name', 'pending');
            set(payload, 'ecommerce.paymentMethod', 'rogers mastercard');
          }
          //selfServeComplete global

          let result: any = this.interaction_types.find(
            (item: { path: any }) => item.path === event.url
          );

          // error setup
          let errors_arr = [
            {
              category: 'self serve',
              type: 'user',
              message: result?.error_desc,
              code: '',
              API: '',
              field: '',
            },
          ];
          set(payload, 'page.name', pageName);
          set(payload, 'page.hierarchy', event.url.split('/').filter(Boolean));
          set(payload, 'page.language', sessionStorage.getItem('lang'));
          set(payload, 'page.province', sessionStorage.getItem('province'));
          set(
            payload,
            'applicationName',
            'eKYC|codecommit::ca-central-1://ekyc-app-frontend'
          );
          set(payload, 'events.pageView', true);
          set(payload, 'site.name', 'RogersBank');
          set(payload, 'personalization', {});
          if (result !== undefined) {
            set(payload, 'errors', errors_arr);
            set(payload, 'events.errors', true);
          }
          // console.log("payload",payload)
          if (pageName == 'select_document') {
            this.globalSelectDocument(payload);
          } else {
            (<any>window)._trackData('adlEvent', 'a', payload);
          }
        }
    }
    });
  }

  //-------------------------------------
  // payload for page view global
  //--------------------------------------
  globalSelectDocument(payload:any){
      set(payload, 'events.selfServeStart', true);
      set(payload, 'selfServe.type', 'transaction');
      set(payload, 'selfServe.name', 'eKYC - global');
      set(payload, 'form.name', 'eKYC - verify your identity');
      set(payload, 'form.stepname', 'choose your id');
      set(payload, 'form.lob', 'credit card');
      set(payload, 'events.formView', true);
      set(payload, 'miscellaneous.type', 'self serve');
      set(payload, 'miscellaneous.name', 'begin');
      (<any>window)._trackData('adlEvent', 'a', payload);
    }

  //--------------------------------------
  // events capture
  //--------------------------------------
  getEventsData() {
    this.contentService.ctaEvents$.subscribe((cta) => {
      if (cta) {
        let result: any = this.interaction_types.find(
          (item: { cta_name: any; }) => item.cta_name === cta
        );
        let payload:any = {};
        set(payload, 'interaction.type', 'click');
        set(payload, 'interaction.name', result?.interation_name);
        set(payload, 'events.interaction', true);
        set(payload, 'personalization', {});
        set(payload, 'site.name', 'RogersBank');
        set(payload, 'applicationName', 'eKYC|codecommit::ca-central-1://ekyc-app-frontend');
        set(payload, 'page.language', sessionStorage.getItem('lang'));
        set(payload, 'page.province', sessionStorage.getItem('province'));
        set(payload, 'page.name', this.eventPageName.split('/').slice(-1)[0]);
        set(payload, 'page.hierarchy', this.eventPageName.split('/').filter(Boolean));
        //selfServeStart global 
        let fields=[{
          'name':'choose your id',
          'value':sessionStorage.getItem("user_selected_document")
        }]
        if (fields[0].value == 'International Passport') {
          fields[0].value =
            sessionStorage.getItem('country_code') + ' ' + 'Passport';
        } else {
          fields[0].value = sessionStorage.getItem('user_selected_document');
        }
        if (result.cta_name === 'chooseID') {
          set(payload, 'events.formComplete', true);
          set(payload, 'form.fields', fields);
        }
      
        //selfServeStart global 
        (<any>window)._trackData('adlEvent', 'a', payload);
      }
    });
  }
 


}

import { environment } from 'src/environments/environment';

export function loadAdobeScript(): () => Promise<void> {
  return (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = environment.adobeScript;
      script.onload = () => resolve();
      script.onerror = () => resolve();
      script.async = true;
      document.head.appendChild(script);
      //  ((<any>window)._analyticsHelpers ??= {queue: []}).queue ??= [];
       (<any>window)._analyticsHelpers ??= {queue: []};
        // (<any>window)._analyticsHelpers.site = 'RogersBank';
        if (!(<any>window)._trackData) {
          (<any>window)._trackData = (payload: any) => (<any>window)._analyticsHelpers.queue.push(payload);
        }
    });
  };
}
