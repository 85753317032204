import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { set } from 'lodash';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private router: Router

  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        map(res => {
          const successResp:any=res
          if(successResp?.body?.errorMessage || successResp?.body?.errorMsg ){
            let errors = [
              {
                "category": "self serve",
                "type": "http",
                "code": successResp?.body?.errorCode,
                "API": successResp?.url,
                "field": "",
                "message": successResp?.body?.errorMessage || JSON.stringify(successResp) ||successResp?.body?.errorMsg
              }
            ]
            this.errorsEvent(errors);
          } else if(successResp?.body?.status == 'Error'){
            let errors = [
              {
                "category": "self serve",
                "type": "http",
                "code": successResp?.body?.state?.result?.code,
                "API": successResp?.url,
                "field": "",
                "message": successResp?.body?.state?.result?.message || JSON.stringify(successResp)
              }
            ]
            this.errorsEvent(errors);
          }
          return res
        }),
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
               console.log("interceptor")
          } else {
              
            let errors = [
              {
                "category": "self serve",
                "type": "http",
                "code": error?.status,
                "API": error?.url,
                "field": "",
                "message": error?.error?.message || JSON.stringify(error)
              }
            ]
            this.errorsEvent(errors);
          }
          return throwError(errorMsg);
        })
      )
  }

errorsEvent(errors:any){
  if(this.router.url=='/ekyc/mobile_handoff'){
    let payload = {}
    set(payload, 'errors', errors);
    set(payload, 'events.errors', true);
    set(payload, 'personalization', {});
    set(payload, 'site.name', 'RogersBank');
    set(payload, 'applicationName', 'eKYC|codecommit::ca-central-1://ekyc-app-frontend');
    set(payload, 'page.language', sessionStorage.getItem('lang'));
    set(payload, 'page.province', sessionStorage.getItem('province'));
    set(payload, 'page.hierarchy', this.router.url.split('/').filter(Boolean));
    set(payload, 'page.name', this.router.url.split('/').slice(-1)[0]);
    console.log(payload, 'error');
    (<any>window)._trackData('adlEvent', 'a', payload);
  }else {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let payload = {}
        set(payload, 'errors', errors);
        set(payload, 'events.errors', true);
        set(payload, 'personalization', {});
        set(payload, 'site.name', 'RogersBank');
        set(payload, 'applicationName', 'eKYC|codecommit::ca-central-1://ekyc-app-frontend');
        set(payload, 'page.language', sessionStorage.getItem('lang'));
        set(payload, 'page.province', sessionStorage.getItem('province'));
        set(payload, 'page.hierarchy', event.url.split('/').filter(Boolean));
        set(payload, 'page.name', event.url.split('/').slice(-1)[0]);
        console.log(payload, 'error');
        (<any>window)._trackData('adlEvent', 'a', payload);
      }
    })
  }


}

}
