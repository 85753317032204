import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class AuthguardService  {
  constructor(
    private router: Router,
    private deviceService: DeviceDetectorService
  ) {}
  
  // to check the device 
  canActivate(): any {
    let desktop = this.deviceService.isDesktop();
    if (desktop) {
      this.router.navigate(['']);
      return false;
    } else {
      return true;
    }
  }
  
}
