<!-- Navbar -->
@if (apiservice.visible) {
  <nav class="navbar navbar-expand-lg  navbar-fixed-top px-4">
    <a  rel="noopener" class="navbar-brand" href={{headerData?.logo?.fields.url}} target="_blank"   id="analytics_cta">
      <img class="logo"  src={{logo_image?.url}} alt={{logo_image?.alt}}>
    </a>
    <ul class="navbar-nav " style="margin-left: auto;">
      <li class="nav-item">
        <a  class="nav-link text-white" id="analytics_cta" (click)="changeLanguage()" >{{language}}</a>
      </li>
    </ul>
  </nav>
}

<!-- display the component here -->
<div class="content ">
  <router-outlet></router-outlet>
</div>

<!-- footer for desktop view -->
@if (apiservice.visible) {
  <footer id="footer" class="d-flex align-items-center justify-content-center px-4">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-2 footer_steps pr-0">
          <p class="footer-block">{{copyRight}}</p>
        </div>
        <div class="col-md-6 footer_steps pl-0">
          <ul class="footer_list" >
            @for (links of footerlinks; track links) {
              <li> <a  rel="noopener" id="analytics_cta" href={{links.fields.url}} target="_blank"> {{links.fields.text}}</a></li>
            }
          </ul>
        </div>
        <div class="col-md-4 need_help">
          <p class=" help d-flex align-items-center "><img class="telephone_icon" src="../../../assets/images/icons/telephone_icon.png" alt="telephone_icon"> <a class="text-white" href="tel:1 855-775-2265">{{helpCTA}}</a></p>
        </div>
      </div>
    </div>
  </footer>
}

<!-- footer for mobile view -->

@if (apiservice.visible) {
  <footer id="footer_mobile">
    <div class="container-fluid">
      <div class="row ">
        <div class="col-md-12 p-0">
          <div class="help ">
            <p class=" d-flex align-items-center "><img class="telephone_icon_mobile" src="../../../assets/images/icons/telephone_icon.png" alt="telephone_icon"> <a class="text-white" href="tel:1 855-775-2265">{{helpCTA}}</a></p>
          </div>
          <div class="links">
            <ul class="footer_list">
              @for (links of footerlinks; track links) {
                <li> <a  rel="noopener" id="analytics_cta" href={{links.fields.url}} target="_blank"> {{links.fields.text}}</a></li>
              }
            </ul>
          </div>
          <div>
            <p class="footer-block">{{copyRight}}</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
}