import { Component, OnInit } from '@angular/core';

import { BackendApiService } from 'src/app/services/backend-api.service';
import { ContentfulService } from 'src/app/services/contentful.service';

@Component({
    selector: 'app-technical-diffculties',
    templateUrl: './technical-diffculties.component.html',
    styleUrls: ['./technical-diffculties.component.css'],
    standalone: true
})
export class TechnicalDiffcultiesComponent implements OnInit {
  technicalErrorData: any;

  constructor(private contentService: ContentfulService, private apiservice:BackendApiService,
    
    ) {
    this.apiservice.showNavbar();

   }

  ngOnInit(): void {
    let lang = sessionStorage.getItem('lang');
    this.getContentfulData(lang);
    this.chooseLanguage();
  }

  chooseLanguage(){
    this.contentService.language.subscribe((currentLang: any)=>{
        this.getContentfulData(currentLang);
    })
  }

  getContentfulData(currentLang:any) {
    const flow=sessionStorage.getItem('flow')
    if(flow=='queued'){
    this.contentService
      .getEntry(currentLang ,'page', { 'fields.slug': '/ekyc/global-errors-offline', include: 10 })
      .then((res) => {
        this.technicalErrorData = res[0].fields;
      });
    } else {
      this.contentService
      .getEntry(currentLang ,'page', { 'fields.slug': '/ekyc/global-errors-real-time', include: 10 })
      .then((res) => {
        this.technicalErrorData = res[0].fields;
      });
    }
  }

}
