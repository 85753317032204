import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createClient, Entry } from 'contentful';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  Endpoint = environment.apiUrl;
  public isLoading = new BehaviorSubject(false);
  contentCred$: Observable<any>;
  private myMethodSubject = new Subject<any>();

  private cdaClient: any
  language = new Subject<string>();

  // events
  private ctaEvents = new BehaviorSubject<any>(null);
  public ctaEvents$ = this.ctaEvents.asObservable();

  // application_pending adobe tagging
  private applicationPendingAdobe = new BehaviorSubject<any>(null);
  public applicationPendingAdobe$ = this.applicationPendingAdobe.asObservable();

  constructor(private http: HttpClient) {
    this.contentCred$ = this.myMethodSubject.asObservable();
  }

  async getEntry(lang: string, type: string, query?: object): Promise<Entry<any>[]> {
    const res = await this.cdaClient.getEntries(Object.assign({
      content_type: type, locale: lang
    }, query)
    ); return res.items;
  }

  idUpdate(data: any): Observable<any> {
    const params = new HttpParams().set('Hide-Loader', 'false');
    return this.http.post(this.Endpoint + 'id-update', data, {
      responseType: 'json', params
    });
  }

  contentCred(data: any) {
    this.cdaClient = createClient({
      space: data.contentfulSecrets.space,
      environment: data.contentfulSecrets.environment,
      accessToken: data.contentfulSecrets.accessToken
    })

  }

  // events
  sendCTAName(data: any) {
    this.ctaEvents.next(data)
  }

  //send application_pending adobde tagging
  sendAppPendingAdobe(data: any){
    this.applicationPendingAdobe.next(data)

  }

}
