import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionIDService  {

  constructor(private router:Router,
    ) { }

  // check sessionId before navigating
  canActivate():any{
   let sessionId= sessionStorage.getItem('sessionId');
    if (sessionId == undefined || sessionId==null || sessionId=='') {
      this.router.navigate(['/ekyc/technical_error']);
      return false;
    }
    else {
      return true;
    }
  }

  // check user selected document type before navigating
  canActivateChild():any{
    let document= sessionStorage.getItem('document_selected');
     if (document == undefined || document==null || document=='' || document != 'true') {
       this.router.navigate(['/ekyc/technical_error']);
       return false;
     }
     else {
       return true;
     }
   }

}

