import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { BackendApiService } from './backend-api.service';
import { ContentfulService } from './contentful.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptorService implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  public isLoading = new Subject<boolean>();

  constructor(private backendapi:BackendApiService, private contentfulSer:ContentfulService) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.backendapi.isLoading.next(this.requests.length > 0);
    this.contentfulSer.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const hideLoader = req.params.get('Hide-Loader');
    if (hideLoader) {
        this.isLoading.next(false);
        // not to load the loader on this params
        req = req.clone({ params: req.params.delete('Hide-Loader','true') });
    }
      else {
        this.requests.push(req);
        this.backendapi.isLoading.next(true);
      }

    
    return Observable.create((observer: { next: (arg0: HttpResponse<any>) => void; error: (arg0: any) => void; complete: () => void; }) => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
                this.removeRequest(req);
                observer.next(event);
            }
          },
          err => {
            this.removeRequest(req);
            observer.error(err);
          },
          () => {
              this.removeRequest(req);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }

}
