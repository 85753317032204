@if (allowRoute==true) {
  <router-outlet></router-outlet>
}

<div class="container-fluid"  [hidden]="!loading">
  <div class="row">
    <div class="col-md-12 text-center loading-spinner">
      <div class="loader-div">
        <div class="text-center load" >
          <div id="loading"></div><span class="loader-text" >Loading...</span>
        </div>
      </div>
    </div>
  </div>
</div>


